import * as React from "react"
import styled from "styled-components"
import { SubHeader, Header } from "../shared/type"

export const Intro = (): JSX.Element => {
  return (
    <>
      <Container>
        <Header>
          Your Own Personal Web
          & Marketing Agency
        </Header>
        <SubHeader style={{color: "var(--primary)"}}>
          Who Studio is the first à la carte agency to put accessible,
          affordable web services in everyones hands.
        </SubHeader>
        <p>
          Who Studio was established by a team of like-minded entrepreneurs and
          web developers focused on bringing custom & affordable web, branding,
          and digital media solutions to everyone. Think of us as your own
          personal web & advertising department. For the price of your daily
          coffee run, or your monthly phone bill you can have a full service
          digital agency at your fingertips. Browse our á la carte menu, or call
          us today to find the package that’s right for your business.
        </p>
      </Container>
    </>
  )
}

const Container = styled.div`


  @media (min-width: 768px) {
    grid-column: 2;
  }
`