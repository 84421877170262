import * as React from "react"
import { Head } from "../components/head"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Intro } from "../components/_about/intro"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import lightbulb from "../logos/lightbulb.svg"
import { SectionContainer, SectionContent } from "../components/shared/containers"

const WhoWeAre = (): JSX.Element => {
  const { whoWeAreImage } = useStaticQuery(
    graphql`
      query {
        whoWeAreImage: file(
          relativePath: { eq: "who-we-are/whoTeamShort.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO title="Who We Are" />
      <Head title={"Who We Are"} />
      <Layout>
        <TeamPhoto>
          <WhoWeAreImg
            fluid={whoWeAreImage.childImageSharp.fluid}
            role="img"
            aria-label="Who Studio web design and development team making websites"
            style={{height: "100%", width: "100%"}}
          />
        </TeamPhoto>
        <SectionContainer>
          <Content>
          <Lightbulb
            src={lightbulb}
            alt="Stylized outline of a lightbulb/question mark to the left of our story."
          />
          <Intro />
          </Content>
        </SectionContainer>
      </Layout>
    </>
  )
}
export default WhoWeAre

const  Content = styled(SectionContent)`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
`

const Lightbulb = styled.img`
  display: none;
  @media (min-width: 768px) {
    display: initial;
    height: auto;
    width: 100%;
  }
`

const TeamPhoto = styled.div`
  grid-column: -1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  height: 300px;
  @media (min-width: 768px) {
    height: 70vh;
  }
`

const WhoWeAreImg = styled(BackgroundImage)`

`